import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { IBlogPost } from "utils/types"
import { convertBodyToJSX } from "utils/helpers"
import SocialLinks from "./components/SocialLinks"

interface BlogViewProps {
  post: IBlogPost
}
export default function BlogView({ post }: BlogViewProps) {
  // Let all links of the blog post open externally in a new tab
  useEffect(() => {
    document
      .querySelectorAll(".blog-post-body a")
      .forEach(x => x.setAttribute("target", "__blank"))
  }, [])

  return (
    <div className="mt-8 md:mt-12 mb-20">
      <div className="flex px-[7%] justify-center">
        <div className="max-w-7xl w-full">
          <h1
            className="text-3xl md:text-4xl lg:text-5xl font-medium"
            style={{ lineHeight: 1.25 }}
          >
            {post.title}
          </h1>
          <h3 className="text-gray-500 text-xl md:text-2xl lg:text-4xl mt-3 mb-8">
            {post.subtitle}
          </h3>

          <div className="flex space-x-3 items-center">
            {post.authorImage && (
              <GatsbyImage
                className="w-8 h-8 rounded-full"
                alt={`Image of ${post.authorName}`}
                image={post.authorImage.gatsbyImageData}
              />
            )}

            <div className="flex flex-col sm:flex-row flex-wrap sm:space-x-3 sm:items-center">
              <p className="font-semibold text-gray-800">
                By {post.authorName}
              </p>
              <p className="text-gray-400 text-sm sm:text-base">
                {post.date || post.createdAt}
              </p>
            </div>
          </div>
        </div>
      </div>

      {post.image && (
        <GatsbyImage
          className="w-full h-56 sm:h-72 md:h-[400px] my-12 md:my-20 object-cover"
          alt="post image"
          image={post.image.gatsbyImageData}
        />
      )}

      <div className="flex px-[7%] justify-center">
        <div className="max-w-7xl w-full">
          <div className="flex flex-col-reverse lg:flex-row items-start">
            <SocialLinks {...post} />
            <div className="w-full flex justify-center">
              <div className="max-w-3xl mb-8 w-full">
                <h3 className="text-xl font-medium mb-12 lg:mb-16">
                  {post.captionText}
                </h3>

                <div className="blog-post-body text-gray-800">
                  {convertBodyToJSX(post.body)}
                </div>
              </div>
            </div>
            <div className="w-10"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
