import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import BlogView from "modules/BlogView/BlogView"
import { IBlogPost } from "utils/types"
import OtherPosts from "modules/BlogView/components/OtherPosts"

interface BlogPostTemplateProps {
  data: {
    contentfulBlogPost: IBlogPost
    previous: Pick<IBlogPost, "title" | "slug" | "image"> | null
    next: Pick<IBlogPost, "title" | "slug" | "image"> | null
  }
}

function BlogPostTemplate({ data }: BlogPostTemplateProps) {
  return (
    <Layout>
      <SEO
        title={data.contentfulBlogPost.title}
        description={data.contentfulBlogPost.description}
        image={data.contentfulBlogPost.image?.url}
      />
      <BlogView post={data.contentfulBlogPost} />
      <OtherPosts previous={data.previous} next={data.next} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      subtitle
      title
      authorName
      description
      captionText
      tags
      date(formatString: "MMMM Do, YYYY")
      createdAt(formatString: "MMMM Do, YYYY")
      image {
        url
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      authorImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      body {
        raw
        references {
          contentful_id
          url
          title
          width
          height
          description
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
