import React, { ReactNode } from "react"
import { Tooltip, TooltipProps } from "react-tippy"

// Here we create an object for our Tooltip props, with a prop called children with our actual children elements.
interface CustomTooltipProps extends TooltipProps {
  children: ReactNode
}

export default function CustomTooltip(props: CustomTooltipProps) {
  // Now we inject all props from above in the clone.
  const CustomTooltip = React.cloneElement(<Tooltip />, {
    ...props,
  })

  // In the end of our file we return the custom cloned component.
  return CustomTooltip
}
