import React from "react"
import { Link } from "gatsby"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"

import { IBlogPost } from "utils/types"
import { GatsbyImage } from "gatsby-plugin-image"

interface OtherPostsProps {
  previous: Pick<IBlogPost, "title" | "slug" | "image"> | null
  next: Pick<IBlogPost, "title" | "slug" | "image"> | null
}

export default function OtherPosts({ previous, next }: OtherPostsProps) {
  return (
    <div className="flex px-[7%] justify-center mb-24">
      <div className="max-w-3xl w-full">
        <div className="flex justify-between">
          <div className="max-w-[8rem] md:max-w-[16rem]">
            {previous && (
              <div className="flex flex-col items-end">
                <div className="flex items-center mb-2 space-x-4">
                  <BsArrowLeft fontWeight="bolder" />
                  <p className="font-medium">Previous</p>
                </div>
                <Link to={`/${previous.slug}`}>
                  <div className="flex">
                    <p className="text-right text-sm text-emerald twoLineClamp underline">
                      {previous.title}
                    </p>
                    {previous.image && (
                      <GatsbyImage
                        className="w-10 ml-3 hidden sm:block h-10 object-cover flex-shrink-0 rounded-md"
                        alt="previous image"
                        image={previous.image.gatsbyImageData}
                      />
                    )}
                  </div>
                </Link>
              </div>
            )}
          </div>

          <div className="max-w-[8rem] md:max-w-[16rem]">
            {next && (
              <div className="flex flex-col items-start">
                <div className="flex items-center mb-2 space-x-4">
                  <p className="font-medium">Next</p>
                  <BsArrowRight fontWeight="bolder" />
                </div>
                <Link to={`/${next.slug}`}>
                  <div className="flex">
                    {next.image && (
                      <GatsbyImage
                        className="w-10 mr-3 h-10 hidden sm:block object-cover flex-shrink-0 rounded-md"
                        alt="next image"
                        image={next.image.gatsbyImageData}
                      />
                    )}
                    <p className="text-left text-sm text-emerald twoLineClamp underline">
                      {next.title}
                    </p>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <Link to="/" className="mt-8 text-emerald underline">
            Back to home
          </Link>
        </div>
      </div>
    </div>
  )
}
