import React from "react"
import { FaTwitter, FaLinkedinIn, FaFacebookSquare } from "react-icons/fa"
import { FiShare2 } from "react-icons/fi"
import CustomTooltip from "components/Tooltip"
import { IBlogPost } from "utils/types"

export default function SocialLinks({ slug, title, authorName }: IBlogPost) {
  const linkToPost = `https://blog.emerald.cash/${slug}`

  const onCopyPostLink = () => {
    navigator.clipboard.writeText(linkToPost)
  }

  const getSocialLink = (platform: string) => {
    switch (platform) {
      case "facebook":
        return `https://www.facebook.com/dialog/feed?&app_id=2891061904457967&link=${linkToPost}&display=popup&quote="${title}" by ${authorName} on Emerald Blog`
      case "twitter":
        return `https://twitter.com/intent/tweet?text="${title}" by ${authorName} on Emerald Blog&url=${linkToPost}`
      case "linkedin":
        return `https://www.linkedin.com/shareArticle?mini=true&url=${linkToPost}&title=${title}&summary="${title}" by ${authorName} on Emerald Blog&source=LinkedIn`
    }
  }

  return (
    <div className="lg:w-10 flex space-x-4 lg:space-x-0 lg:flex-col lg:sticky top-16 lg:space-y-8 text-2xl text-gray-400">
      <a href={getSocialLink("twitter")} target="__blank">
        <FaTwitter />
      </a>
      <a href={getSocialLink("facebook")} target="__blank">
        <FaFacebookSquare />
      </a>
      <a href={getSocialLink("linkedin")} target="__blank">
        <FaLinkedinIn />
      </a>
      <CustomTooltip
        title="Link copied to clipboard!"
        position="bottom"
        trigger="click"
        arrow
      >
        <button onClick={onCopyPostLink}>
          <FiShare2 />
        </button>
      </CustomTooltip>
    </div>
  )
}
